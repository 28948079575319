import { CanAccessDirective } from './../../../helpers/directives/can-access.directive';
import { BreadCrumbsComponent } from './../../component/bread-crumbs/bread-crumbs.component';
// import { DetailNotificationComponent } from './../../../modules/iop-module/dashboard/dashboard-layout/detail-notification/detail-notification.component';
import { TopBarComponent } from './../../../modules/iop-module/dashboard/dashboard-layout/top-bar/top-bar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigateItemsComponent } from 'src/app/shared/component/navigate-items/navigate-items.component';
import { TranslateModule } from '@ngx-translate/core';
import { SensorLineChartComponent } from 'src/app/shared/component/sensor-line-chart/sensor-line-chart.component';
import { ChartModule } from 'angular-highcharts';
import { MapSliderComponent } from '../../component/map/map-slider/map-slider.component';
import { MaterialModule } from 'src/app/material/material.module';
import { RouterModule } from '@angular/router';
import { NotificationSliderComponent } from 'src/app/modules/iop-module/dashboard/dashboard-layout/notification-slider/notification-slider.component';
import { SensorDonutChartComponent } from '../../component/sensor-donut-chart/sensor-donut-chart.component';
import { MessageBoxComponent } from '../../component/message-box/message-box.component';
import { MapComponent } from 'src/app/shared/component/map/map.component';
import { RemoveSensorComponent } from '../../component/remove-sensor/remove-sensor.component';
import { EditSensorComponent } from '../../component/edit-sensor/edit-sensor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditCustomerComponent } from '../../component/edit-customer/edit-customer.component';
import { TooltipDirective } from 'src/app/helpers/directives/tooltip.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { MonthPickerComponent } from '../../component/month-picker/month-picker.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  declarations: [
    EditCustomerComponent,
    EditSensorComponent,
    RemoveSensorComponent,
    NavigateItemsComponent,
    SensorLineChartComponent,
    MapSliderComponent,
    TopBarComponent,
    NotificationSliderComponent,
    BreadCrumbsComponent,
    SensorDonutChartComponent,
    MessageBoxComponent,
    MapComponent,
    CanAccessDirective,
    TooltipDirective,
    MonthPickerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxIntlTelInputModule,
    ChartModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule
  ],
  exports: [
    EditCustomerComponent,
    EditSensorComponent,
    RemoveSensorComponent,
    NavigateItemsComponent,
    SensorLineChartComponent,
    MapSliderComponent,
    MaterialModule,
    TopBarComponent,
    BreadCrumbsComponent,
    NotificationSliderComponent,
    RouterModule,
    SensorDonutChartComponent,
    CanAccessDirective,
    MapComponent,
    TooltipDirective,
    MonthPickerComponent
  ],entryComponents:[
    MessageBoxComponent
  ]
})
export class UseSharedComponentModule { }
