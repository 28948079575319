import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { timeZoneApi } from 'src/app/common';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
import { MessageBoxService, MessageDefinition } from '../message-box/message-box.service';
import { CustomerService } from 'src/app/modules/iop-module/customers/customer.service';
import { Status } from 'src/app/helpers/config/status';
import { timeZone } from './../../../classModel/timeZoneResponse';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';

enum Address {
  POSTAL_CODE = 'postal_code',
  STATE = 'administrative_area_level_1',
  COUNTRY = 'country',
  CITY = 'locality'
}

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit {

  TooltipLabel = TooltipLabel;
  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  setCountryISO: String;

  @Input() getCustomerDetails: any;
  @Input() reloadScreenFlag: any;
  @Output() setPopupClose = new EventEmitter<boolean>();
  @Output() setReloadScreen = new EventEmitter<boolean>();

  map: google.maps.Map;
  lat: number;
  lng: number;
  marker: any;
  mapZoomLevel: number = 3;
  initialShowMarker: boolean;

  @ViewChild('adrSearch', { static: true }) public searchElement: ElementRef;
  @ViewChild('searchMap', { static: true }) gmapElement: ElementRef;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  
  editCustomer: FormGroup;
  currentYear: number;
  files: File;
  customerImage: any;

  newCustomerBtnFlag: boolean = false;
  countries: { text: string; value: string; }[];
  countriesArray: any;
  customerNameErrorMsg: string;
  customerId: number;

  constructor(private ngZone: NgZone, private fb: FormBuilder,private _commonDataService: CommonDataService, private _messageBoxService: MessageBoxService, private _customerService: CustomerService) {

    let date = new Date();
    this.currentYear = date.getFullYear();

    // form
    this.editCustomer = this.fb.group({
      customerId: [{ value: '', disabled: true }, Validators.required],
      customerName: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50)]],
      customerAddress: ['', [Validators.required,Validators.minLength(5),Validators.maxLength(250)]],
      customerCountry: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50)]],
      customerState: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50)]],
      customerCity: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50)]],
      customerZipCode: ['', [Validators.minLength(4),Validators.maxLength(10)]],
      customerContactPerson: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(50)]],
      customerContactEmail: ['',[Validators.required, Validators.pattern('[A-Z0-9a-z._%+-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,64}'),Validators.maxLength(255)]],
      customerContactNumber: ['', [Validators.required]],
      locationLattitude: [''],
      locationLongitude: ['']  
    });
   
   }

  ngOnInit() {

    const countries = this._customerService.getCountriesNameList();
    this.countriesArray = [];
    countries.forEach(item =>{
      this.countriesArray.push(item.text);
    })
    this.countriesArray = [...this.countriesArray];
    this.setCountryISO = this.CountryISO['India'];

    this.creatLayout();
    
  }

  creatLayout() {
    
    this.customerId = this.getCustomerDetails.customerId;

    this.editCustomer.controls['customerId'].enable();
    this.editCustomer.controls['customerId'].setValue(this.getCustomerDetails.customerId);
    this.editCustomer.controls['customerName'].setValue(this.getCustomerDetails.customerName);
    this.editCustomer.controls['customerAddress'].setValue(this.getCustomerDetails.customerAddress);
    this.editCustomer.controls['customerCountry'].setValue(this.getCustomerDetails.customerCountry);
    this.editCustomer.controls['customerState'].setValue(this.getCustomerDetails.customerState);
    this.editCustomer.controls['customerCity'].setValue(this.getCustomerDetails.customerCity);
    this.editCustomer.controls['customerZipCode'].setValue(this.getCustomerDetails.customerZipCode);
    this.editCustomer.controls['customerContactPerson'].setValue(this.getCustomerDetails.customerContactPerson);
    this.editCustomer.controls['customerContactEmail'].setValue(this.getCustomerDetails.customerContactEmail);
    this.editCustomer.controls['customerContactNumber'].setValue(this.getCustomerDetails.customerContactNumber); 
    
    this.editCustomer.controls['locationLattitude'].setValue(this.getCustomerDetails.locationId.locationLattitude); 
    this.editCustomer.controls['locationLongitude'].setValue(this.getCustomerDetails.locationId.locationLongitude); 
    
    this.customerImage = this.getCustomerDetails.customerLogo;

    if(this.getCustomerDetails['locationId']) {
      if(this.getCustomerDetails['locationId']['locationLattitude'] && this.getCustomerDetails['locationId']['locationLongitude']) {
        this.lat = this.getCustomerDetails['locationId']['locationLattitude'];
        this.lng = this.getCustomerDetails['locationId']['locationLongitude'];
        this.initialShowMarker = true;
        this.mapZoomLevel = 15;
      } else {
        this.lat = 0;
        this.lng = 0;
        this.initialShowMarker = false;
      }
    }else {
      this.lat = 0;
      this.lng = 0;
      this.initialShowMarker = false;
    }
   
    this.initSearch();

  }

  // img upload
  triggerFile() {
    this.fileInput.nativeElement.click();
  }

  getFiles(event) {
    const self = this;
    let temp: any;
    const type = event.target.files[0].type;
    this.files = event.target.files[0];

    if (this.files.type.indexOf('image') >= 0) {

      let FileSize = this.files.size / 1024 / 1024; 

      if(FileSize > 5) { // not allow more than 5 MB

        this.files = null;
        this._messageBoxService.openMessageBox('alert', 'file size should be less than 5 MB');

      }else { // allow less then 5 MB

        const fileName = this.files['name'];
        let splitArray = fileName.split('.');

        if(splitArray.indexOf('png') > -1 || splitArray.indexOf('jpg') > -1 || splitArray.indexOf('jpeg') > -1 || splitArray.indexOf('PNG') > -1 || splitArray.indexOf('JPG') > -1 || splitArray.indexOf('JPEG') > -1) {

          const myReader: FileReader = new FileReader();
          myReader.readAsDataURL(this.files);
          let resultSet = '';
          myReader.onloadend = (e) => {
            const columns = (<string>myReader.result).split(/\r\n|\r|\n/g);
            for (let i = 0; i < columns.length; i++) {
              resultSet = resultSet.concat(columns[i].split(' ')[0]);
              this.customerImage = resultSet;
            }
          }
          
        } else {

          this.files = null;
          this._messageBoxService.openMessageBox('alert', 'file formats should be JPG/JPEG/PNG');

        }

      }
      
    } else {
      this.files = null;
      this._messageBoxService.openMessageBox('alert', 'please choose a image file');
    }
  }

  // // customer name paste
  // pasteCustomerNameEvent(event: ClipboardEvent) {

  //   let clipboardData = event.clipboardData || window['clipboardData'];
  //   let pastedText = clipboardData.getData('text');
  //   this.getCustomerNameDetails(pastedText);
    
  // }

  // // check customer name isExits
  // getCustomerNameDetails(resultString) {
  //   const customer_name = resultString.trim();
  //   let url = `${customer_name}?customerId=${this.customerId}`;
  //   if(customer_name.length > 0 && customer_name.length < 25) {
  //     if(this.getCustomerDetails.customerName.toLowerCase() != customer_name.toLowerCase()) {
  //       this._customerService.getCustomerIsExits(url).subscribe((customerNameResp: any) => {
  //         if (customerNameResp.status === Status.OK) {
  //           this.editCustomer.get('customerName').setValue(customer_name);
  //           this.editCustomer.get('customerName').setErrors({ customerNameWrong: false });
  //           this.editCustomer.get('customerName').setErrors(null);
  //         } else { // error
  //           this.customerNameErrorMsg = customerNameResp['message'];
  //           this.editCustomer.get('customerName').setValue(customer_name);
  //           this.editCustomer.get('customerName').setErrors({ customerNameWrong: true });
  //         }
  //       });
  //     }
  //   }
  // }

  // on confirm
  onConfirm(event) {
    this.newCustomerBtnFlag = event.target.checked;
    this.editCustomer.controls['customerNew'].setValue(this.newCustomerBtnFlag);
  }

  onRemoveImg(e) {
    this.files = null;
    this.customerImage = null;
  }
  
  onSubmit(formValue) {

    const data = new FormData();
    const imageFile = this.files ? this.files : this.customerImage;
    data.append('file', imageFile);
    const customer_name = formValue['customerName'].trim();
    formValue['customerName'] = customer_name;
    const email = formValue['customerContactEmail'].trim().toLowerCase();
    formValue['customerContactEmail'] = email;
    const phoneNum = formValue['customerContactNumber']['internationalNumber'];
    formValue['customerContactNumber'] = phoneNum;
    data.append('customer', JSON.stringify(formValue));

    let setUrl = `${customer_name}?customerId=${this.customerId}`;

    if(this.getCustomerDetails.customerName.toLowerCase() != customer_name.toLowerCase()) { // user modified the customer name
      this._customerService.getCustomerIsExits(setUrl).subscribe((customerNameResp: any) => {
        if (customerNameResp.status === Status.OK) {

          this.editCustomer.get('customerName').setValue(customer_name);
          this.editCustomer.get('customerName').setErrors({ customerNameWrong: false });
          this.editCustomer.get('customerName').setErrors(null);
          // update customer API
          this._customerService.updateCustomer(data, this.customerId).subscribe((res: any) => {
            if (res.status === 'ok') {
              this._messageBoxService.openMessageBox('alert', MessageDefinition.CUSTOMERUPDATED).subscribe((e: any) => {
                this.setReloadScreen.emit(true);
              });
            }else {
              this._messageBoxService.openMessageBox('alert', res.message);
            }
          });

        } else { // error

          this.customerNameErrorMsg = customerNameResp['message'];
          this.editCustomer.get('customerName').setValue(customer_name);
          this.editCustomer.get('customerName').setErrors({ customerNameWrong: true });

        }
      });

    } else {

      // update customer API
      this._customerService.updateCustomer(data, this.customerId).subscribe((res: any) => {
        if (res.status === 'ok') {
          this._messageBoxService.openMessageBox('alert', MessageDefinition.CUSTOMERUPDATED).subscribe((e: any) => {
            this.setReloadScreen.emit(true);
          });
        }else {
          this._messageBoxService.openMessageBox('alert', res.message);
        }
      });

    }
  }

  // close popup
  onCancelPopup() {
    this.setPopupClose.emit(false);
  }

  initSearch() {
    this.initMap();
    const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement);
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        this.lat = place.geometry.location.lat();
        this.lng = place.geometry.location.lng();
        console.log("sdafsf > ",this.lat, this.lng)
        this.editCustomer.controls['locationLattitude'].setValue(this.lat);
        this.editCustomer.controls['locationLongitude'].setValue(this.lng);
        this.extractAddress(place);
        this.timeZone(this.lat, this.lng);
        this.setMarker(true);
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
      });
    });
  }

  initMap() {
    const mapProp = {
      center: new google.maps.LatLng({ lat: this.lat, lng: this.lng }),
      zoom: this.mapZoomLevel,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      scaleControl: true,
    };
    const self = this;
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    if (this.initialShowMarker) { this.setMarker(false) }
    google.maps.event.addListener(this.map, 'click', function (event) {
      self.lat = event.latLng.lat();
      self.lng = event.latLng.lng();
      self.timeZone(self.lat, self.lng);
      self.setMarker(false);
      self.setLatLng(self.lat, self.lng);
      self.findAndSetAddressFromLatLng(self.lat, self.lng);
    });

  }

  timeZone(lat, lng) {
    this._commonDataService.getNormalHttpData(timeZoneApi.url +
      lat + ',' + lng + timeZoneApi.timestamp + timeZoneApi.apiKey)
      .subscribe((res: timeZone) => {
        if (res.status.toLowerCase() === Status.OK) {
          //console.log(res)
          this.editCustomer.controls['timeZoneId'].setValue(res.timeZoneId)
          // this.selected = res.timeZoneId
        }
      });
  }

  setMarker(zoom) {
    const self = this;
    if (this.marker) {
      const lt = self.lat;
      const lg = self.lng;
      self.setLatLng(lt, lg);
      this.findAndSetAddressFromLatLng(lt, lg);
      self.marker.setPosition({ 'lat': this.lat, 'lng': this.lng });
    } else {
      this.marker = new google.maps.Marker({
        position: { 'lat': this.lat, 'lng': this.lng },
        map: this.map,
        draggable: true
      });
      this.marker.addListener('dragend', (function (e) {
        this.lat = e.latLng.lat();
        this.lng = e.latLng.lng();
        const lt = e.latLng.lat();
        const lg = e.latLng.lng();
        self.setLatLng(lt, lg);
        self.timeZone(this.lat, this.lng);
        self.findAndSetAddressFromLatLng(lt, lg);
      }));
    }
    if (zoom) {
      this.map.setCenter({ 'lat': this.lat, 'lng': this.lng });
      this.map.setZoom(15);
    }
  }

  setLatLng(lat, lng) {
    this.editCustomer.controls['locationLattitude'].setValue(lat);
    this.editCustomer.controls['locationLongitude'].setValue(lng);
  }

  findAndSetAddressFromLatLng(lat, lng) {
    const self = this;
    const geoCoder = new google.maps.Geocoder();
    const latLng = { 'lat': lat, 'lng': lng };
    geoCoder.geocode({ 'location': latLng }, function (results, status: google.maps.GeocoderStatus) {
      if (status === google.maps.GeocoderStatus.OK) {
        self.extractAddress(results[0]);
      } else {
        this.editCustomer.controls['customerAddress'].setValue('');
        this.editCustomer.controls['customerCity'].setValue('');
        this.editCustomer.controls['customerCountry'].setValue('');
        this.editCustomer.controls['customerZipCode'].setValue('');
        this.editCustomer.controls['customerState'].setValue('');
      }
    });
  }
  
  extractAddress(place) {
    this.editCustomer.controls['customerAddress'].setValue(place.formatted_address);
    place.address_components.map(e => {
      if (e.types.includes(Address.CITY)) {
        this.editCustomer.controls['customerCity'].setValue(e.long_name);
      }
      if (e.types.includes(Address.COUNTRY)) {
        this.editCustomer.controls['customerCountry'].setValue(e.long_name);
        // counter flag dynamic update from google map
        if(this.setCountryISO != this.CountryISO[e.long_name]) {
          this.editCustomer.controls['customerContactNumber'].setValue('');
          this.setCountryISO = this.CountryISO[e.long_name];
        }
      }
      if (e.types.includes(Address.POSTAL_CODE)) {
        this.editCustomer.controls['customerZipCode'].setValue(e.long_name);
      }
      if (e.types.includes(Address.STATE)) {
        this.editCustomer.controls['customerState'].setValue(e.long_name);
      }
    })
  }

}
