import { ThemeService } from './helpers/services/theme.service';
import { RefreshTokenService } from './helpers/interceptors/refreshToken.Service';
import { RefreshTokenInterceptor } from './helpers/interceptors/refreshToken.interceptor';
import { TokenInterceptor } from './helpers/interceptors/token.interceptor';
import { HTTPListener, HTTPStatus } from './helpers/interceptors/httpLoader.interceptor';
import { AuthenticationService } from './helpers/authentication.service';
import { SharedModule } from './shared/module/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageServiceModule } from 'angular-webstorage-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from 'src/app/helpers/error-handler';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './shared/component/loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
// import { CanAccessDirective } from './helpers/directives/can-access.directive';
// import { LoginService } from './modules/login/login.service';
// import { NavigateItemsComponent } from './shared/component/navigate-items/navigate-items.component';
// import { BreadCrumbsComponent } from './shared/component/bread-crumbs/bread-crumbs.component';
import { NoCacheHeadersInterceptor } from './helpers/interceptors/no.cache.headers.interceptor';

import { TooltipModule, TooltipConfig } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule, BsDropdownConfig  } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    StorageServiceModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(), // Add this line
    ToastrModule.forRoot({
      maxOpened: 5
    }),
    TranslateModule.forRoot()
  ],
  providers: [
    RefreshTokenService,
      AuthenticationService,
    ThemeService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    },
    { provide: BsDropdownConfig, useValue: { autoClose: true } }, // Add this line
    { provide: TooltipConfig, useValue: { placement: 'top', triggers: 'hover' } } // Add this line
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }